import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eafaa78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reporting-tasks" }
const _hoisted_2 = { class: "reporting-tasks__grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reporting_tasks_empty = _resolveComponent("reporting-tasks-empty")!
  const _component_reporting_navigation = _resolveComponent("reporting-navigation")!
  const _component_report_cards = _resolveComponent("report-cards")!
  const _component_reporting_tasks_priority = _resolveComponent("reporting-tasks-priority")!
  const _component_reporting_tasks_source = _resolveComponent("reporting-tasks-source")!
  const _component_reporting_timeline = _resolveComponent("reporting-timeline")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, null, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isEmptyMode)
          ? (_openBlock(), _createBlock(_component_reporting_tasks_empty, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_reporting_navigation, {
                filters: _ctx.filters,
                "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event)),
                boards: "",
                class: "mb-3"
              }, null, 8, ["filters"]),
              _createVNode(_component_report_cards, {
                cards: _ctx.stats,
                "no-hover": "",
                class: "mb-6"
              }, null, 8, ["cards"]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_reporting_tasks_priority),
                _createVNode(_component_reporting_tasks_source)
              ]),
              _createVNode(_component_reporting_timeline, {
                data: _ctx.timelineChart,
                "timeline-options": _ctx.timelineTasksOptions,
                class: "mb-6"
              }, null, 8, ["data", "timeline-options"])
            ], 64))
      ])
    ]),
    _: 3
  }))
}