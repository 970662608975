import { h, render } from 'vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import TmPriority from '@/components/shared/TmPriority.vue'
import { formatNumber } from '@/services/utils'
import { getOrCreateLegendList } from '@/services/charts/chart'

import { useChartTooltip } from '@/compositions/chart/chartTooltip'
import type { ChartTooltipDataType } from '@/compositions/chart/chartTooltip'

const { initTooltip, removeTooltip, fillTooltip } = useChartTooltip()

export const doughnutChartHtmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart: Record<string, any>, args: Record<string, any>, options: Record<string, any>): void {
    const ul = getOrCreateLegendList(chart, options.legendClass)

    while (ul.firstChild) {
      ul.firstChild.remove()
    }

    const items = chart.options.plugins.legend.labels.generateLabels(chart)
    const chartData = chart.getDatasetMeta(0)
    const chartDataTotal = chartData.total

    items.forEach((item: any, i: number) => {
      const data = chartData._dataset.data[item.index]

      const li = document.createElement('li')
      li.classList.add('doughnut__item')

      if (data.priority) {
        const priorityComponent = h(
          TmPriority,
          {
            type: data.priority,
            iconOnly: true,
          }
        )

        render(priorityComponent, li)
      } else {
        const dot = document.createElement('div')
        dot.classList.add('doughnut__item__dot')
        dot.style.background = item.fillStyle
        li.appendChild(dot)
      }

      if (data.country) {
        const countryComponent = h(
          TmFlag,
          {
            country: data.country,
            size: 'small',
          }
        )
        render(countryComponent, li)
      }

      const text = document.createElement('div')
      text.classList.add('doughnut__item__text')
      text.textContent = item.text + ' - '

      const percents = document.createElement('div')
      percents.classList.add('doughnut__item__percents')

      const percentsText = (chartData._parsed[i] / chartDataTotal * 100).toFixed(1)
      percents.textContent = percentsText + '%'

      const value = document.createElement('div')
      value.classList.add('doughnut__item__value')
      value.textContent = `(${formatNumber(chartData._parsed[i])})`

      li.appendChild(text)
      li.append(percents)
      li.append(value)
      ul.appendChild(li)
    })
  },
}

const externalTooltipHandler = (context: Record<string, any>): void => {
  const { chart, tooltip } = context
  const tooltipClass = 'doughnut__tooltip'
  initTooltip(context, tooltipClass)

  if (tooltip.opacity === 0) {
    removeTooltip()
    return
  }

  if (tooltip.body) {
    const bodyLines = tooltip.dataPoints
    const chartDataTotal = chart.getDatasetMeta(0).total
    const tooltipData: ChartTooltipDataType[] = []

    bodyLines.forEach((body: any, i: number) => {
      const colors = tooltip.labelColors[i]
      const data = chart.getDatasetMeta(0)._dataset.data[body.dataIndex]

      const title = body.label
      const color = colors.backgroundColor

      const dataText = data.tooltipValueLabel || 'Value'
      const dataValue = formatNumber(body.parsed)

      tooltipData.push({
        text: dataText,
        value: dataValue,
        color: color,
      })

      const dataPercentsText = data.tooltipPercentLabel || '% of all'
      const dataPercentsValue = (body.parsed / chartDataTotal * 100).toFixed(1) + '%'

      tooltipData.push({
        text: dataPercentsText,
        value: dataPercentsValue,
        color: color,
      })

      fillTooltip(tooltipClass, title, tooltipData)
    })
  }
}

export const doughnutChartOptions = {
  borderWidth: 3,
  borderRadius: 4,
  cutout: '45%',
  hoverBorderColor: 'white',
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    htmlLegend: {
      legendClass: 'doughnut__legend',
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: externalTooltipHandler,
    },
  },
  onHover: (event: Record<string, any>, elements: any[]): void => {
    const chart = event.chart
    if (elements.length) {
      const item = elements[0]

      chart.data.datasets[0].backgroundColor.forEach((color: string, index: number, colors: string[]) => {
        if (index === item.index) {
          colors[index] = color.length === 9 ? color.slice(0, -2) : color
        } else {
          colors[index] = color.length === 9 ? color : color + '4D'
        }
      })
    } else {
      chart.data.datasets[0].backgroundColor.forEach((color: string, index: number, colors: string[]) => {
        colors[index] = color.length === 9 ? color.slice(0, -2) : color
      })
    }
    chart.update()
  },
}
