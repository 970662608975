
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ReportingFullEmpty from '@/components/pages/reporting/ReportingFullEmpty.vue'

export default defineComponent({
  components: {
    TmButton,
    ReportingFullEmpty,
  },
})
