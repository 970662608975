import { ref, computed } from 'vue'
import { rootScrollClassName } from '@/definitions/_general/_data/general'
import { tooltipId } from '@/services/charts/chart'
import type { ComputedRef } from 'vue'
import { rgbToHex, styleVars } from '@/compositions/styleVariables'

const context = ref()
const tooltipElementRef = ref()

export type ChartTooltipDataType = {
  color: string;
  text?: string;
  valuePostfix?: string;
  value: string;
}

type UseChartTooltipType = {
  getContext: ComputedRef<Record<string, any>>;
  setContext: (context: Record<string, any>) => void;
  initTooltip: (context: Record<string, any>, tooltipClass: string, horizontal?: boolean) => HTMLElement;
  fillTooltip: (tooltipClass: string, title: string, data: ChartTooltipDataType[]) => void;
  setTooltipPosition: () => void;
  removeTooltip: () => void;
}

export const useChartTooltip = (): UseChartTooltipType => {
  const getContext = computed(() => context.value)
  const isHorizontal = ref(false)

  const setContext = (newContext: Record<string, any>) => {
    context.value = newContext
  }

  const createTooltipElement = (tooltipClass: string) => {
    const rootScroll = document.querySelector(`.${rootScrollClassName}`)!
    const tooltipEl = document.createElement('div')

    tooltipEl.setAttribute('id', tooltipId)
    tooltipEl.classList.add(tooltipClass)

    document.querySelector('body')!.appendChild(tooltipEl)
    rootScroll.addEventListener('scroll', setTooltipPosition)

    tooltipElementRef.value = tooltipEl
  }

  const getTooltip = (tooltipClass: string): HTMLElement => {
    if (!tooltipElementRef.value) {
      createTooltipElement(tooltipClass)
    } else {
      tooltipElementRef.value.innerHTML = ''
    }

    setTooltipPosition()
    return tooltipElementRef.value
  }

  const initTooltip = (context: Record<string, any>, tooltipClass: string, horizontal = false): HTMLElement => {
    setContext(context)

    const tooltip = getTooltip(tooltipClass)
    isHorizontal.value = horizontal

    return tooltip
  }

  const setTooltipPosition = (): void => {
    if (!Object.keys(getContext.value).length && !tooltipElementRef.value) { return }

    const { chart, tooltip } = getContext.value
    const { x: chartPositionX, y: chartPositionY } = chart.canvas.getBoundingClientRect()

    tooltipElementRef.value.style.opacity = '1'
    if (isHorizontal.value) {
      const chartBaseX = tooltip.dataPoints[0].element.base
      tooltipElementRef.value.style.left = (tooltip.caretX - chartBaseX) / 2 + chartPositionX + chartBaseX + 'px'
      tooltipElementRef.value.style.top = tooltip.caretY + chartPositionY - 7 + 'px'
    } else {
      tooltipElementRef.value.style.left = tooltip.caretX + chartPositionX + 'px'
      tooltipElementRef.value.style.top = tooltip.caretY + chartPositionY + 'px'
    }
  }

  const removeTooltip = (): void => {
    const rootScroll = document.querySelector(`.${rootScrollClassName}`)!
    rootScroll.removeEventListener('scroll', setTooltipPosition)

    tooltipElementRef.value.remove()
    tooltipElementRef.value = null
    setContext({})
  }

  const fillTooltip = (tooltipClass: string, title: string, data: ChartTooltipDataType[]): void => {
    const titleHTML = document.createElement('div')
    titleHTML.classList.add(`${tooltipClass}__header`)
    titleHTML.textContent = title

    tooltipElementRef.value.appendChild(titleHTML)

    data.forEach((item: ChartTooltipDataType) => {
      const dataItem = document.createElement('div')
      dataItem.classList.add(`${tooltipClass}__item`)
      dataItem.style.setProperty('--circle-color', item.color)

      const dataItemTitle = document.createElement('div')
      dataItemTitle.textContent = item.text || 'Value'

      const dataItemValue = document.createElement('div')
      dataItemValue.textContent = item.value

      if (item.valuePostfix) {
        const dataItemValuePostfix = document.createElement('span')
        dataItemValuePostfix.textContent = item.valuePostfix
        dataItemValuePostfix.style.marginLeft = '2px'
        dataItemValuePostfix.style.color = rgbToHex(styleVars.distinct)

        dataItemValue.appendChild(dataItemValuePostfix)
      }

      dataItem.appendChild(document.createElement('div'))
      dataItem.appendChild(dataItemTitle)
      dataItem.appendChild(dataItemValue)
      tooltipElementRef.value.appendChild(dataItem)
    })
  }

  return {
    setContext,
    removeTooltip,
    fillTooltip,
    getContext,
    setTooltipPosition,
    initTooltip,
  }
}
