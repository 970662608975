
import { defineComponent } from 'vue'
import { DoughnutChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import {
  doughnutChartHtmlLegendPlugin, doughnutChartOptions
} from '@/definitions/shared/charts/_general/doughnut/data'

Chart.register(...registerables)

export default defineComponent({
  components: {
    DoughnutChart,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: '128px',
    },
    justifyCenter: {
      type: Boolean,
    },
  },
  setup() {
    return {
      doughnutChartOptions,
      doughnutChartHtmlLegendPlugin,
    }
  },
})
