
import { defineComponent, computed } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ReportCards from '@/components/shared/templates/reports/ReportCards.vue'
import ReportingNavigation from '@/components/pages/reporting/ReportingNavigation.vue'
import ReportingTimeline from '@/components/pages/reporting/ReportingTimeline.vue'
import ReportingTasksEmpty from '@/components/pages/reporting/tasks/ReportingTasksEmpty.vue'
import ReportingTasksPriority from '@/components/pages/reporting/tasks/ReportingTasksPriority.vue'
import ReportingTasksSource from '@/components/pages/reporting/tasks/ReportingTasksSource.vue'
import { reportsFilter } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'
import { useModes } from '@/compositions/modes'
import { reportsTasksStats, reportsTasksStatsHalfEmpty, timelineTasksOptions } from '@/definitions/reporting/tasks/data'
import {
  reportsTasksTimelineChartData,
  reportsTasksTimelineChartDataHalfEmpty,
  reportsTasksSourceChartData
} from '@/definitions/shared/charts/reporting/data'

export default defineComponent({
  components: {
    PageContent,
    ReportCards,
    ReportingNavigation,
    ReportingTimeline,
    ReportingTasksEmpty,
    ReportingTasksPriority,
    ReportingTasksSource,
  },
  setup() {
    const { isEmptyMode, isEmptyHalfMode } = useModes()
    const { filters } = useFilters(reportsFilter)

    const stats = computed(() => isEmptyHalfMode.value ? reportsTasksStatsHalfEmpty : reportsTasksStats)
    const timelineChart = computed(() => isEmptyHalfMode.value ? reportsTasksTimelineChartDataHalfEmpty : reportsTasksTimelineChartData)

    return {
      isEmptyMode,
      filters,
      stats,
      timelineChart,
      timelineTasksOptions,
      reportsTasksSourceChartData,
    }
  },
})
