import type { ReportCardType } from '@/definitions/shared/templates/types'

export const reportsTasksStats: ReportCardType[] = [
  {
    title: 'New tasks',
    tooltip: 'Total number of tickets created during the selected period.',
    counter: '248',
    percent: {
      value: '+12.8%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '210',
  },
  {
    title: 'Resolved tasks',
    tooltip: 'Total number of tasks that reached the last stage in the board during the selected period.',
    counter: '165',
    percent: {
      value: '+9.2%',
      change: 'red',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '58',
  },
  {
    title: 'Avg. resolution time',
    tooltip: 'The average time taken from task creation to reaching the final stage on the board during the selected period.',
    counter: '3d 2h',
    percent: {
      value: '-14.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '2d 10h',
  },
]

export const reportsTasksStatsHalfEmpty: ReportCardType[] = reportsTasksStats.map(stat => ({
  ...stat,
  counter: '0',
  previously: '0',
})).map(({ percent, ...stat }) => stat)

export const timelineTasksOptions = [
  'New tasks',
  'Resolved tasks',
  'Avg. task resolution time',
]
