import type { Rgba } from '@/compositions/styleVariables'
import { rgbToHex } from '@/compositions/styleVariables'

export const tooltipId = 'chart-tooltip'

export const getColorObject = (rgb: Rgba): Record<string, string> => {
  return {
    borderColor: rgbToHex(rgb),
    backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, .1)`,
    pointBackgroundColor: rgbToHex(rgb),
    pointBorderColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, .48)`,
  }
}

export const getOrCreateLegendList = (chart: Record<string, any>, legendClass: string): HTMLElement => {
  const legendContainer = chart.canvas.parentNode.parentNode.querySelector(`.${legendClass}`)
  let listContainer = legendContainer?.querySelector('ul')

  if (!listContainer) {
    listContainer = document.createElement('ul')

    legendContainer?.appendChild(listContainer)
  }

  return listContainer
}
